<template>
 <v-container
    id="privacy"
    fluid
    tag="section"
    class="px-12"
  >
    <div class="text-center">
      <v-img :src="logo" max-height="50px" contain class="mt-12"/>
      <h5>{{ $appName }}</h5>
      <h6 class="my-4">Privacy Policy</h6>
    </div>
<p>
{{ $appName }} "the App” provides order fulfillment, shipping and inventory management "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
</p><p>
<b>Personal Information the App Collects</b>
</p><p>
When you install the App, we are automatically able to access certain types of information from your Shopify account: 
<ul>
<li>
Read & Write orders: To be able to get your orders and change their status to fulfilled
</li><li>
  Read & Write inventory: To be able to check your product inventory and update when requested by the user
</li><li>
  Read products: To get data from your products such as weights, value (for customs) and HS Codes + Country of origin (for customs)
</li><li>
  Read locations: To know which locations you have connected on your store so you can choose which fulfillment location you are using the app for.
</li><li>
  Read Shipping: To know which shipping methods you are offering your customers, so you can match them with the shipping supplier of your choice.
</li>
</ul>
</p><p>
Additionally, we collect the following types of personal information from you when you install the app: Your name, email, store name and your store URL.
</p><p>
<b>How Do We Use Your Personal Information?</b>
</p><p>
We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. 
</p><p>
<b>Sharing Your Personal Information:</b> We are sharing information about you and your customers with only necessary parties, such as the shipping services you integrate with us in order to produce shipping labels. <b>We are not sharing any of your customer data for advertisements etc or with any party irrelevant for the scope of our services!</b>
</p><p>
Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
</p><p>
<b>Your Rights:</b> If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
</p><p>
Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make a shipment through the service), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
</p><p>
<b>Data Retention:</b> When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
</p><p>
<b>Changes:</b> We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
</p><p>
<b>Contact Us:</b> For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info@zodiapps.com or by mail using the details provided below:
</p><p>
Zodiapps AB, Mailbox 1602, 41142 Göteborg, Sweden
</p>

</v-container>

</template>
<script>



export default {
  components: {

  },
  computed: {

  },
  
  
  filters: {
    
  },
  data() {
    return {
      logo: require('@/assets/favicon-192.png'),
    }
  },
  methods: {
  }    
}

</script>
